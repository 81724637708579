<template>
  <div class="appLayoutView">
    <div class="appLayoutView_content">
      <keep-alive>
        <router-view v-if='$route.meta.keepAlive' :key="getKey"></router-view>
      </keep-alive>
      <router-view v-if='!$route.meta.keepAlive'></router-view>
    </div>
    <van-tabbar v-if="!hideTabBar" :value="currentActiveModule" @input="handleRoute" active-color="#333"
      inactive-color="#333"
      :fixed="false">
      <van-tabbar-item v-for="item in footerNavOptions" :key="item.name" :name="item.name">
        <span class="navItem_title">{{ item.title }}</span>
        <template #icon="props">
          <van-badge :dot="item.name==='message'&&newMsg">
            <img class="navItem_icon"
              :src="`${footerNavIconBasePath}/${props.active?item.activeIcon:item.normalIcon}`">
          </van-badge>
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import {newMsgFlag} from '@/api/message'


const footerNavIconBasePath = '/images/appLayout'
export default {
  watch: {
    hideTabBar: {
      immediate: true,
      deep: true,
      handler (val) {
        if (val) {
          clearInterval(this.timeoutTimer)
        } else {
          this.getMsgFlag();
          this.timer()
        }
      }
    }
  },
  data () {
    return {
      newMsg: false
    }
  },
  computed: {
    getKey() {
      return this.$route.path
    },
    // 当前所在路由模块
    currentActiveModule () {
      const {moduleName} = this.$route.meta || {}
      return moduleName
    },
    hideTabBar () {
      return this.$route.meta.hideTabBar
    },
    // 导航图标基础路径
    footerNavIconBasePath () {
      return footerNavIconBasePath
    },
    // 导航配置
    footerNavOptions () {
      const {children} = this.allRouteMap.get('app')
      return children.filter(item => !item.hidden).map(item => {
        const {name, meta} = item
        return {...meta, name}
      })
    },
    // 所有路由 map
    allRouteMap () {
      const allRouteMap = new Map()
      const {routes} = this.$router.options
      const loopGetRoute = (routes) => {
        routes.map(item => {
          const {name, children} = item
          if (name) {
            allRouteMap.set(name, item)
          }
          if (children) {
            loopGetRoute(children)
          }
        })
      }
      loopGetRoute(routes)
      return allRouteMap
    }
  },
  methods: {
    /**
     * 模块跳转
     * @param routeName
     */
    handleRoute (routeName) {
      this.getMsgFlag()
      this.$router.push({name: routeName})
    },
    // 获取底部消息小圆点
    getMsgFlag () {
      newMsgFlag().then(res => {
        if (res.remindFlag || res.privateLetterFlag) {
          this.newMsg = true
        }else {
          this.newMsg = false
        }
      })
    },
    timer () {
      // if(this.timeoutTimer) clearTimeout(this.timeoutTimer)
      // this.timeoutTimer = setTimeout(()=>{
      //   this.getMsgFlag();
      //   this.timer();
      // }, 3000)
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  //隐藏 tabbar 底部border
  .van-hairline--top-bottom::after, .van-hairline-unset--top-bottom::after {
    border-bottom: none;
  }
}

.appLayoutView {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;

  &_content {
    position: relative;
    flex: 1;
    background-color: #f6f6f6;
    overflow: hidden;
  }

  &_footer {
    height: $app-view-footerHeight;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

.navItem {
  &_icon {
    width: 20px;
  }

  &_title {
    font-size: 12px;
  }
}
</style>
