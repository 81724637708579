

// 闪布login
import request from "@/utils/request";

export function getAttMessage(params) {
    return request({
        url: '/api/zeekr-article-new/message/listAtMessage',
        method: 'get',
        params
    })
}

//

export function listCommentMessage(params) {
    return request({
        url: '/api/zeekr-article-new/message/listCommentMessage',
        method: 'get',
        params
    })
}
export function listThumbsUpMessage(params) {
    return request({
        url: '/api/zeekr-article-new/message/listThumbsUpMessage',
        method: 'get',
        params
    })
}



// todo 获取系统消息
export function getSystemMessage(params) {
    return request({
        url: '/api/zeekr-article-new/sysMessage/list',
        method: 'get',
        params
    })
}

//艾特评论点赞数量
export function getAttCount() {
    return request({
        url: '/api/zeekr-article-new/message/count',
        method: 'get'
    })
}

//是否有新消息
export function newMsgFlag() {
    return request({
        url: '/api/zeekr-article-new/message/messageFlag',
        method: 'get'
    })
}

// 获取成员加人申请列表
export function getMemberApplyFor(params) {
    return request({
        url: '/api/zeekr-article-new/circleApplyMessage/list',
        method: 'get',
        params
    })
}

// 同意成员加人申请
export function agreeMemberApplyFor(data) {
    return request({
        url: '/api/zeekr-article-new/circleApplyRecord/agreeApply',
        method: 'post',
        data
    })
}

// 拒绝成员加人申请
export function refusedMemberApplyFor(data) {
    return request({
        url: '/api/zeekr-article-new/circleApplyRecord/refuseApply',
        method: 'post',
        data
    })
}
// 回复评论消息
export function replyCommentinfo(data) {
    return request({
        url: '/api/zeekr-article-new/comment/replyCommentMessage',
        method: 'post',
        data
    })
}